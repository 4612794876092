export function calculateMercatorFromLatLng({lat, lng }) {

    var rMajor = 6378137; //Equatorial Radius, WGS84
    var shift  = Math.PI * rMajor;
    var x      = lng * shift / 180;
    var y      = Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180);
    y = y * shift / 180;
    
    return {'X': x, 'Y': y};
}

export function calculateZoomFromMercator(mercatorA, mercatorB, mapWidth = 1000, mapHeight = 500) {
    // double b/c zoom is for entire map while distance is only from center (destination) to hotel
    const distanceY = Math.abs(mercatorA.Y - mercatorB.Y) * 2;
    const distanceX = Math.abs(mercatorA.X - mercatorB.X) * 2;

    const zoomY= Math.floor(Math.log2(mapHeight * 40000000 / (256 * distanceY)));
    const zoomX= Math.floor(Math.log2(mapWidth * 40000000 / (256 * distanceX)));
    return Math.min(zoomX, zoomY);
}
