import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';
import { FavouriteProvider } from 'context/favourite';
import { ImageViewerProvider } from 'context/imageViewer';
import { CmsDataProvider } from 'context/cmsData';
import AmpEmailAPI from 'api/AmpEmailAPI';


import './styles/tailwind_output.css';
import './styles/global.css';
import './styles/typography.css';
import './component/layout'

import * as serviceWorker from './serviceWorker';
import history from './utils/history.js';

const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const pixelId = "699703301383008";
ReactPixel.init(pixelId, pixelOptions);
ReactPixel.trackSingle(pixelId, "PageView");

window.dataLayer.push({ event: 'virtualPageView' });

history.listen(location => {
  AmpEmailAPI.trackPage(location.pathname.replace("/", ""));
  window.dataLayer.push({ event: 'virtualPageView' });
  ReactPixel.trackSingle(pixelId, "PageView");
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
      <ImageViewerProvider>
        <FavouriteProvider>
          <CmsDataProvider>
            <App />
          </CmsDataProvider>
        </FavouriteProvider>
      </ImageViewerProvider>
    </GoogleReCaptchaProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

