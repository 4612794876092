import React from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage as Img } from 'react-lazy-load-image-component';

const BELOW_SMALL = window.screen.width < 640;
const BELOW_MEDIUM = window.screen.width < 768;

const FrameImage = ({ isExternal = true, optimizeImageSize = true, optimizeImageFactor = 1, Image, src = '', alt, className = '', height, width, ...props }) => {
    if (Image) {
        alt = Image.alternativeText
        src = Image.url;
        height = Image.height;
        width = Image.width;

        if (optimizeImageSize) {
            if (BELOW_SMALL) {
                src = Image.formats?.small?.url || src;
            } else if (BELOW_MEDIUM) {
                src = Image.formats?.medium?.url || src;
            } else if (window.innerWidth < 1024 * optimizeImageFactor) {
                src = Image.formats?.large?.url || src;
            }
        }
    }
    let finalSrc = src;

    if (isExternal) {
        finalSrc = `${process.env.REACT_APP_API_DOMAIN}${src}`;
    }

    if (!src) {
        return <span className={`${className} bg-gray-300 text-white text-xl`}>Image Not Available</span>
    }

    return <Img threshold={200} height={height} width={width} placeholder={<span className="bg-gray-300 text-white text-xl">Image Loading</span>} className={`${className}`} src={finalSrc} alt={alt} {...props}></Img>
}

const FrameL = ({ children, className = '', Image, src, alt, isExternal = true, imgClasses = '', hasImage = true, ratio = '16:9', fixedHeight, ratioAboveSm, ratioAboveMd, ratioAboveLg, ratioAboveXl, optimizeImageSize = true, optimizeImageFactor = 1 }) => {
    const style = {
    }

    if (fixedHeight) {
        style.height = fixedHeight;
        ratio = '1:0';
    }

    return <frame-l
        style={style}
        class={className}
        ratio={ratio}
        ratioAboveSm={ratioAboveSm}
        ratioAboveMd={ratioAboveMd}
        ratioAboveLg={ratioAboveLg}
        ratioAboveXl={ratioAboveXl}>
        {hasImage && <FrameImage optimizeImageFactor={optimizeImageFactor} optimizeImageSize={optimizeImageSize} className={imgClasses} Image={Image} src={src} alt={alt} isExternal={isExternal}></FrameImage>}
        {children}
    </frame-l>
};

FrameL.propTypes = {
    src: PropTypes.string,
    isExternal: PropTypes.bool,
};

FrameL.defaultProps = {
    src: '',
    isExternal: true,
    alt: '',
    className: '',
};

export default FrameL;
export { FrameImage as Img };

